import React from "react";
import theme from "theme";
import { Theme, Text, Section, Button, Icon, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				АВТОМОЙКА БыстроЧисто
			</title>
			<meta name={"description"} content={"Необыкновенные впечатления от мойки автомобиля, выходящие за рамки простой чистки."} />
			<meta property={"og:title"} content={"АВТОМОЙКА БыстроЧисто"} />
			<meta property={"og:description"} content={"Необыкновенные впечатления от мойки автомобиля, выходящие за рамки простой чистки."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				АВТОМОЙКА БыстроЧисто
			</Override>
			<Override slot="text4" />
			<Override slot="text2" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box1" />
			<Override slot="link3" />
			<Override slot="text3" />
			<Override slot="link4" />
			<Override slot="icon" />
			<Override slot="box2" />
		</Components.Header>
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-5.jpg?v=2024-06-07T06:52:09.836Z) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="--headline3"
				letter-spacing="1px"
			>
				Добро пожаловать на автомойку БыстроЧисто
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="--headline2"
				letter-spacing="1px"
				color="#ff6d43"
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Необыкновенные впечатления от мойки автомобиля, выходящие за рамки простой чистки.
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 250px 0px 250px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Мы понимаем, что ваш автомобиль — это больше, чем средство передвижения, это продолжение вас самих. Вот почему мы стремимся предоставлять первоклассные услуги, которые помогут вашему автомобилю выглядеть и чувствовать себя наилучшим образом.
			</Text>
		</Section>
		<Section padding="80px 0 0 0" quarkly-title="Headline-5" lg-padding="2rem 0 1rem 0">
			<Override
				slot="SectionContent"
				padding="0px 20% 0px 0px"
				lg-padding="0px 30% 0px 0px"
				md-padding="0px 0 0px 0px"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0 40px 0px"
				font="--headline2"
				color="#090e28"
				letter-spacing="4px"
				md-font="normal 600 56px/1.2 --fontFamily-sans"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
				text-align="left"
			>
				О БыстроЧисто
			</Text>
			<Text margin="0px 0px 50px 0px" font="--base" color="#334455" sm-margin="0px 0px 25px 0px">
				Компания БыстроЧисто Car Wash была основана с целью произвести революцию в индустрии автомоек. Мы сочетаем новейшие технологии с вниманием к деталям, чтобы гарантировать, что каждый автомобиль, поступающий на наш завод, останется в первозданном состоянии. Наша команда обученных профессионалов увлечена автомобилями и стремится обеспечить превосходное обслуживание клиентов.
			</Text>
			<Button type="link" href="/about" text-align="center" text-decoration-line="initial">
				узнать больше
			</Button>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Передовая технология очистки
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Мы используем новейшее чистящее оборудование и экологически чистые средства, чтобы обеспечить тщательную и безопасную мойку вашего автомобиля.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Быстрое обслуживание
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Мы знаем, что Ваше время ценно. Наш эффективный процесс позволит вам быстро войти и выйти без ущерба для качества стирки.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Счастье клиента
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ваше удовлетворение является нашей приоритетной задачей. Мы делаем все возможное, чтобы каждый клиент ушел довольным.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Опытный персонал
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наша команда экспертов обучена использовать все типы транспортных средств с максимальной осторожностью и точностью.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Доступные пакеты
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Мы предлагаем пакеты услуг, подходящие для любого бюджета, без ущерба для качества.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06%3A52%3A09.847Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06:52:09.831Z"
							srcSet="https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06%3A52%3A09.831Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06:52:09.824Z"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
							srcSet="https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06%3A52%3A09.824Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06:52:09.815Z"
							position="absolute"
							width="100%"
							left={0}
							srcSet="https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06%3A52%3A09.815Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06:52:09.814Z"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
							srcSet="https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06%3A52%3A09.814Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Не соглашайтесь на меньшее, когда дело касается обслуживания вашего автомобиля. Посетите автомойку БыстроЧисто и убедитесь сами. Ваша машина заслуживает лучшего, и вы тоже.
				</Text>
				<Text
					margin="1rem 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Почувствуйте разницу БыстроЧисто
				</Text>
			</Box>
		</Section>
		<Components.Header>
			<Override slot="link4">
				info@autobaratos.com
			</Override>
			<Override slot="link">
				ГЛАВНАЯ
			</Override>
			<Override slot="link2">
				УСЛУГИ
			</Override>
			<Override slot="text">
				ДОБРО ПОЖАЛОВАТЬ НА МОЙКУ БыстроЧисто
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});